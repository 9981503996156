<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>Webhook Receiver</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                A webhook receiver allows your app to receive notifications of new activity as it happens.
                            </p>

                            <template v-if="webhookReceiver">
                                <EditableText :value="webhookReceiver.label" label="Label" @input="saveWebhookReceiverLabel" dense></EditableText>
                                <EditableText :value="webhookReceiver.url" label="URL" @input="saveWebhookReceiverURL" dense></EditableText>
                                <EditableText :value="webhookReceiver.priority" label="Priority" @input="saveWebhookReceiverPriority" dense></EditableText>
                                <EditableTextSelect :value="webhookReceiver.is_active" :items="isActiveChoices" @input="saveWebhookReceiverActive" dense></EditableTextSelect>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
import EditableTextSelect from '@/components/EditableTextSelect.vue';

export default {
    components: {
        EditableText,
        EditableTextSelect,
    },
    data: () => ({
        organization: null,
        webhookReceiver: null,
        error: null,
        isActiveChoices: [
            { text: 'Active', value: true },
            { text: 'Inactive', value: false },
        ],
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isViewReady() {
            return this.webhookReceiver !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditHostnameFormComplete() {
            return this.editableHostname;
        },
    },
    methods: {
        async loadWebhookReceiver() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadWebhookReceiver: true });
                const response = await this.$client.organization(this.$route.params.organizationId).webhookReceiver.get({ id: this.$route.query.id });
                console.log(`loadWebhookReceiver: response ${JSON.stringify(response)}`);
                if (response) {
                    this.webhookReceiver = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadWebhookReceiver: false });
            }
        },
        async editWebhookReceiver(edit = {}) {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                if (Object.keys(edit).length === 0) {
                    this.$bus.$emit('snackbar', { type: 'warn', headline: 'No changes to save' });
                    return;
                }
                this.$store.commit('loading', { editWebhookReceiver: true });
                // TODO: label, priority, url, is_active
                const response = await this.$client.organization(this.$route.params.organizationId).webhookReceiver.edit({ id: this.$route.query.id }, edit);
                console.log(`editWebhookReceiver: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadWebhookReceiver();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit webhook receiver' });
                }
            } catch (err) {
                console.error('failed to edit Hostname', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit webhook receiver' });
            } finally {
                this.$store.commit('loading', { editWebhookReceiver: false });
            }
        },
        async saveWebhookReceiverLabel(value) {
            return this.editWebhookReceiver({ label: value });
        },
        async saveWebhookReceiverURL(value) {
            return this.editWebhookReceiver({ url: value });
        },
        async saveWebhookReceiverPriority(value) {
            let priority;
            if (typeof value === 'string') {
                try {
                    priority = Number.parseInt(value, 10);
                } catch (err) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Priority must be a non-negative integer' }); // TODO: should be a message next to the input
                }
            } else if (typeof value === 'number') {
                priority = Math.round(value);
            }
            return this.editWebhookReceiver({ priority });
        },
        async saveWebhookReceiverActive(value) {
            return this.editWebhookReceiver({ is_active: value });
        },
    },
    mounted() {
        this.loadWebhookReceiver();
    },
};
</script>
